import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { SmartModule } from "./smart.module";
import { CoreModule } from "./core/core.module";
import { AdminRedirectGuard } from "./admin-redirect.guard";

import { environment } from "../environments/environment";

@NgModule({
    declarations: [
        AppComponent
    ],
    providers: [
        {
            provide: "AppConfig",
            useValue: environment
        },
        AdminRedirectGuard
    ],
    imports: [
        CoreModule,
        SmartModule,
        AppRoutingModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export { AppComponent } from "./app.component";
