import { ISmartConfig } from "../app/interfaces/smart-config";
import { environment as localConfig } from "./environment.local";

export const environment: ISmartConfig = {
    ...localConfig,
    production: true,
    staging: false,
    apiUrl: "https://internal-api.mygrocerydeals.com",
    adminAppUrl: "https://admin.mygrocerydeals.com",
    rollbar: {
        ...localConfig.rollbar,
        environment: "production",
        enabled: true
    },
    authentication: {
        ...localConfig.authentication,
        logins: []
    },
    stagingAssetsUrl: "https://assets.staging.ads.mygrocerydeals.com",
    stagingAdUrl: "https://staging.ads.mygrocerydeals.com",
    publishedAdUrl: "https://ads.mygrocerydeals.com",
    publishedVideoAdUrl: "https://video.ads.mygrocerydeals.com"
};
