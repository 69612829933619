import { NgModule } from "@angular/core";

import { MaterialComponentsModule } from "./material-components/material-components.module";
import { SmartRoutingModule } from "./smart-routing.module";

import { SmartComponent } from "./smart.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";


@NgModule({
    imports: [
        MaterialComponentsModule,
        SmartRoutingModule
    ],
    declarations: [
        SmartComponent,
        PageNotFoundComponent
    ]
})
export class SmartModule { }
