import {
    Component, OnInit, Inject, ViewChild
} from "@angular/core";

import { MatSidenav } from "@angular/material/sidenav";

import { User } from "@shopliftr/common-js/shared";
import {
    AuthenticationService,
    FullscreenService,
    SupportService
} from "@shopliftr/common-ng";


@Component({
    selector: "app-smart",
    templateUrl: "./smart.component.html",
    styleUrls: ["./smart.component.scss"]
})
export class SmartComponent implements OnInit {

    @ViewChild(MatSidenav) sideNav: MatSidenav;

    build: string;

    currentUser: User;

    date: any;

    sideNavOpened = true;

    constructor(
        private readonly _authService: AuthenticationService,
        private readonly _fullscreenService: FullscreenService,
        private readonly _supportService: SupportService,
        @Inject("AppConfig") public appConfig: any
    ) { }


    ngOnInit(): void {

        this.currentUser = this._authService.authenticatedUser.getValue();
        this._authService.authenticatedUser.subscribe((loggedInUser: User) => {

            this.currentUser = loggedInUser;
        });

        this.build = this.appConfig.appVersion;

        this._fullscreenService.connect().subscribe((fullscreen) => {

            // ExpressionChangedAfterItHasBeenCheckedError
            setTimeout(() => {

                this.sideNavOpened = !fullscreen;
            });
        });
    }


    logout(): void {

        this._authService.logout();
    }

    /**
     * Invoke the Support service to create a JIRA support ticket.
     */
    onReportProblem(): void {

        this._supportService.requestSupport();
    }
}
