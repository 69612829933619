import { NgModule } from "@angular/core";
import {
    Routes, RouterModule
} from "@angular/router";

import { AuthenticationGuard } from "@shopliftr/common-ng";

import { SmartComponent } from "./smart.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AdminRedirectGuard } from "./admin-redirect.guard";

const routes: Routes = [
    {
        path: "smart",
        component: SmartComponent,
        children: [
            {
                path: "",
                redirectTo: "designer",
                pathMatch: "full"
            },
            {
                path: "designer",
                loadChildren: () => import("./designer/designer.module").then((m) => m.DesignerModule)
            },
            {
                path: "reporting",
                loadChildren: () => import("./reporting/reporting.module").then((m) => m.ReportingModule)
            },
            {
                path: "admin",
                canActivate: [AdminRedirectGuard],
                component: AdminRedirectGuard
            },
            {
                path: "page-not-found",
                component: PageNotFoundComponent
            },
            {
                path: "**",
                redirectTo: "/smart/page-not-found"
            }
        ],
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        AuthenticationGuard
    ]
})
export class SmartRoutingModule { }
