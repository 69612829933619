// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { SupportedApplication } from "@shopliftr/common-js/shared";

import packageInfo from "../../package.json";
import { ISmartConfig } from "../app/interfaces/smart-config";


export const environment: ISmartConfig = {
    appVersion: packageInfo.version,
    appName: SupportedApplication.Smart,
    production: false,
    staging: false,
    rollbar: {
        enabled: false,
        environment: "local-dev",
        accessToken: "a61c8914286b4a6aa29d2b49a439ad72"
    },
    authentication: {
        postLoginPath: "/smart",
        logoUrl: "/assets/images/logo-black-shopliftr.svg",
        heading: "SMART Designer",
        logins: [
            {
                title: "Partner Admin Login",
                email: "uat_partner_admin@shopliftr.com",
                password: "Password!2"
            },
            {
                title: "Sysadmin Login",
                email: "uat_sysadmin@shopliftr.com",
                password: "Password!2"
            }
        ]
    },
    displayUncaughtErrorMessage: true,
    stagingAdUrl: "https://dev.ads.mygrocerydeals.com",
    publishedAdUrl: "https://ads.mygrocerydeals.com",
    publishedVideoAdUrl: "https://video.ads.mygrocerydeals.com",
    apiUrl: "https://dev.internal-api.mygrocerydeals.com",
    adminAppUrl: "https://dev.admin.mygrocerydeals.com",
    stagingAssetsUrl: "https://assets.dev.ads.mygrocerydeals.com",
    timezone: "America/Chicago"
};
