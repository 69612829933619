import { environment as baseConfig } from "./environment.base";

baseConfig.production = true;
baseConfig.staging = false;
baseConfig.apiUrl = "https://internal-api.mygrocerydeals.com";
baseConfig.adminAppUrl = "https://admin.mygrocerydeals.com";
baseConfig.rollbar.environment = "production";
baseConfig.rollbar.enabled = true;
baseConfig.smartAssetsUrl = "https://assets.ads.mygrocerydeals.com/lib/smart",
baseConfig.smart.assetsUrl = "https://assets.staging.ads.mygrocerydeals.com";
baseConfig.authentication.logins = null;
baseConfig.adUrl = "https://staging.ads.mygrocerydeals.com";


export const environment = baseConfig;
