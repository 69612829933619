import {
    ErrorHandler,
    NgModule
} from "@angular/core";

import {
    HttpClientModule,
    HTTP_INTERCEPTORS
} from "@angular/common/http";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";

import {
    AuthenticationModule,
    CommonErrorHandler,
    CommonHttpInterceptor,
    InternalApiInterceptor,
    MgdHotkeyModule,
    NotificationModule,
    ProductSearchModule
} from "@shopliftr/common-ng";


/**
 * Should only be imported once by the app.module. This is not a shared module.
 *
 * */
@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        AuthenticationModule,
        NotificationModule,
        ProductSearchModule,
        MgdHotkeyModule
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: CommonErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CommonHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InternalApiInterceptor,
            multi: true
        }
    ]
})
export class CoreModule { }
