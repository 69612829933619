/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Inject,
    Injectable
} from "@angular/core";

import {
    ActivatedRouteSnapshot, Router, RouterStateSnapshot
} from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class AdminRedirectGuard {

    private readonly _adminAppUrl: string;

    constructor(
        private readonly _router: Router,
        @Inject("AppConfig") private readonly _appConfig: any
    ) {
        this._adminAppUrl = this._appConfig.adminAppUrl;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        window.location.href = this._adminAppUrl;
        return true;
    }
}
