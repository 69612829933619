// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { SupportedApplication } from "@shopliftr/common-js/shared";

import packageInfo from "../../package.json";


export const environment = {
    appVersion: packageInfo.version,
    appName: SupportedApplication.Smart,
    production: false,
    adUrl: "https://dev.ads.mygrocerydeals.com",
    productionAdUrl: "https://ads.mygrocerydeals.com",
    productionAdVideoUrl: "https://video.ads.mygrocerydeals.com",
    staging: false,
    apiUrl: "https://dev.internal-api.mygrocerydeals.com",
    adminAppUrl: "https://dev.admin.mygrocerydeals.com",
    smartAssetsUrl: "https://assets.dev.ads.mygrocerydeals.com/lib/smart",
    smart: {
        assetsUrl: "https://assets.dev.ads.mygrocerydeals.com"
    },
    rollbar: {
        enabled: false,
        environment: "development",
        accessToken: "a61c8914286b4a6aa29d2b49a439ad72"
    },
    timezone: "America/Chicago",
    authentication: {
        postLoginPath: "/smart",
        logoUrl: "/assets/images/logo-black-shopliftr.svg",
        heading: "SMART Designer",
        logins: [
            {
                title: "Partner Admin Login",
                email: "uat_partner_admin@shopliftr.com",
                password: "Password!2"
            },
            {
                title: "Sysadmin Login",
                email: "uat_sysadmin@shopliftr.com",
                password: "Password!2"
            }
        ]
    },
    urlPattern: "(^((https?):\\/\\/?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b|\\{{2,3}" +
        "(assets(\\.base)?|pages)\\}{2,3})([-a-zA-Z0-9@:%_\\+.~#?&\\/=\\[\\];]*)$)|(^mraid.js$)",
    displayUncaughtErrorMessage: true
};
